
export const is_debug = process.env.NODE_ENV === 'development';
export const debug_endpoint = '127.0.0.1:8000';
export const spacecraft = 'spacecraft-it.ru';
export const flagman = 'flagman-climate.ru';
export const gorenerga = 'gorenerga.ru';
export const galaxyHotels = 'galaxy-hotels.ru'
export const vspsha = 'vspsha.ru'
export const teacher = 'teacher-number-one.ru'
export const schema = 'https';
export const debug_schema = 'http';
export const endpoint_version = 'v1';
export const wss_schema = 'wss';
export const ws_schema = 'ws';

const endpoint = vspsha;


export function getURL(urn) {
    if(urn[0] === '/') {
        urn = urn.substring(1);
    }
    return `${is_debug ? debug_schema : schema}://${is_debug ? debug_endpoint : endpoint}/${is_debug ? '' : 'api/'}${urn}`
}

export function getWSSUrl(urn) {
    return `${is_debug ? ws_schema : wss_schema }://${is_debug ? debug_endpoint : endpoint}/${is_debug ? '' : 'ws/'}${urn}`
}

export function getStatic(src) {
    return getURL(`static/${src}`)
}

export function normalizePrice(price) {
    if (!price)
        return;
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}
